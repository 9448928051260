module.exports = {
  "defaultLanguage": "nb-NO",
  "languages": [
    {
      "code": "nb-NO",
      "name": "Norsk",
      "icon": null
    },
    {
      "code": "en-US",
      "name": "English",
      "icon": null
    }
  ]
}