module.exports = {
  "account_page": [
    {
      "language": "nb-NO",
      "slug": "/account"
    },
    {
      "language": "en-US",
      "slug": "/en-US/account"
    }
  ],
  "shopping_cart_page": [
    {
      "language": "nb-NO",
      "slug": "/handlekurv"
    },
    {
      "language": "en-US",
      "slug": "/en-US/shopping-cart"
    }
  ],
  "not_found_page": null
}